import { render, staticRenderFns } from "./buttons.vue?vue&type=template&id=6db422be&scoped=true&lang=pug&"
import script from "./buttons.vue?vue&type=script&lang=coffee&"
export * from "./buttons.vue?vue&type=script&lang=coffee&"
import style0 from "./buttons.vue?vue&type=style&index=0&id=6db422be&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db422be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnCraft: require('/opt/build/repo/nuxt-app/components/globals/btn/craft.coffee').default})
