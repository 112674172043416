import { render, staticRenderFns } from "./buttons.vue?vue&type=template&id=1d9568af&scoped=true&lang=pug&"
import script from "./buttons.vue?vue&type=script&lang=coffee&"
export * from "./buttons.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d9568af",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Buttons: require('/opt/build/repo/nuxt-app/components/globals/buttons.vue').default})
