

















export default

	props:

		# Array of objects with properties {label, id, to, ...} and all props supported by btn.vue
		buttons: Array

